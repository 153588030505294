import axios from 'axios';
// import config from  '../../config/config'
// import axiosHelper from './apiHelper'
const API = axios.create({
    baseURL: process.env.VUE_APP_BOOKING_SVC_API_URL,//config.apiURL, //'https://meghak2.divrt.co/api/v1/',
    // baseURL: 'http://localhost:3000',
    withCredentials: false,
    headers: {
        'x-api-key': process.env.VUE_APP_X_API_KEY,
        'Accept': 'application/json',
        'Content-Type': 'application/json'

    }
});
API.interceptors.request.use(
    request => {
        /** TODO: Add any request interceptors */
        return request;
    },
    error => {
        /** TODO: Do something with response error */
        return Promise.reject(error);
    }
);

API.interceptors.response.use(
    response => {
        /** TODO: Add any response interceptors */
        return response;
    },
    error => {
        /** TODO: Do something with response error */
        return Promise.reject(error);
    }
);

export default API;