<template>
  <div id="validation-qr-code"></div>
</template>

<script>
import { Html5Qrcode } from "html5-qrcode";
import { EventBus } from "@/lib/EventBus";
export default {
  name: "InvalidBooking",
  props: {
    qrbox: {
      type: Number,
      default: function () {
        return 250;
      },
    },
    fps: {
      type: Number,
      default: function () {
        return 10;
      },
    },
    scan: {
      type: String,
      default: function () {
        return "";
      },
    },
    cameraStatus: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data: () => ({
    config: {},
    html5QrcodeScanner: {},
  }),
  watch: {
    cameraStatus(val) {
      console.log("camera status", val);
      if (!val) {
        this.html5QrcodeScanner.stop();
      } else {
        this.html5QrcodeScanner.start(
          { facingMode: "environment" },
          this.config,
          this.onScanSuccess
        );
      }
    },
  },
  mounted() {
      console.log("validation mounted")
    this.config = { fps: this.fps ? this.fps : 10 };
    if (this.qrbox) {
      this.config["qrbox"] = this.qrbox;
    }
    this.html5QrcodeScanner = new Html5Qrcode("validation-qr-code");
    this.html5QrcodeScanner.start(
      { facingMode: "environment" },
      this.config,
      this.onScanSuccess
    );
    // this.html5QrcodeScanner.render(this.onScanSuccess);
  },
  methods: {
    onScanSuccess(decodedText, decodedResult) {
      console.log("decodedCode", decodedText, decodedResult);
      let decodedValidationCode;
      switch (this.scan) {
        case "sms":
          EventBus.$emit("sms-qr-result", decodedText);
          break;
        case "validationCode":
          decodedValidationCode = this.extractValidationCode(decodedText);
          EventBus.$emit("validation-qr-result", decodedValidationCode);
          break;
      }
    },
    extractValidationCode(inputString) {
      const regexMatch = inputString.match(/validationCode=([^&]+)/)
      if (regexMatch) {
        return regexMatch[1];
      } else {
        // If the regex doesn't match, return the inputString
        return inputString;
      }
    },
  },
};
</script>
<style lang="less">
</style>
