import { render, staticRenderFns } from "./GooglePay.vue?vue&type=template&id=f2d62398"
import script from "./GooglePay.vue?vue&type=script&lang=js"
export * from "./GooglePay.vue?vue&type=script&lang=js"
import style0 from "./GooglePay.vue?vue&type=style&index=0&id=f2d62398&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports